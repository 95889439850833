@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
    font-family: "roboto";
}

h1 {
    font-weight: 700;
    font-size: 32px;
    color: #edd8c5;
}

h2 {
    font-size: 26px;
    font-weight: 700;
}

h3 {
    color: #edd8c5;
    font-size: 26px;
    font-weight: 700;
}

h4 {
    color: #edd8c5;
    font-size: 20px;
    line-height: 1.4rem;
    font-weight: 700;
}

p {
    color: white;
    line-height: 1.4rem;
}

.p-large {
    line-height: 1.6em;
    font-size: 1.2rem;
}

a {
    color: white !important;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: .3s;
    width: fit-content;
    text-decoration: none !important;
    &:hover {
        border-bottom: 1px solid white;
    }
    svg {
        margin-right: .3rem;
        margin-bottom: .2rem;
    }
}