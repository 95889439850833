body {
    background-color: #2e2e2e;
}

.link {
    text-decoration: none;
}

.link button {
    background-color: #ba6b4a !important;
    border: 1px solid #ba6b4a;
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover {
        background-color: #aa6345 !important;
        border: 1px solid #aa6345;
        text-decoration: none;
    }
    svg {
        margin-left: .5rem;
    }
}

.ml-2 {
    margin-left: 1rem;
}

.mr-2 {
    margin-right: 1rem;
}

.ml-s-2 {
    margin-left: .5rem;
}

.mr-s-2 {
    margin-right: .5rem;
}

.bullet {
    list-style-type: disc;
    list-style-position: inside;
    li {
        margin-bottom: .7rem;
        color: white;
    }
}