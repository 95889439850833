.footer-custom {
    padding: 2rem 10rem;
    background-color: #ba6b4a;
    margin-top: 3rem;
    &__logo {
        height: 5rem;
        width: auto;
    }
    &-container {
        margin-left: 3rem;
    }
}

@media only screen and (max-width: 850px) {
    .footer-custom {
        display: none !important;
    }
}

@media only screen and (max-width: 1550px) {
    .footer-custom {
        padding: 2rem 3rem;
    }
    }