.page {
    padding: 0 22rem;
    min-height: calc(100vh - 24rem);
}

@media only screen and (max-width: 1550px) {
    .page {
        padding: 0 8rem;
        min-height: calc(100vh - 24rem);
    }
}

@media only screen and (max-width: 850px) {
    .page {
        padding: 0 1rem;
        padding-top: 4rem;
    }
}

.contain {
    background-color: #353535;
    box-shadow: 0px 0px 14px 1px #111;
    margin-top: 3rem;

    &-side {
        border-left: 15px solid #f0d6c5;
    }

    &-rside {
        border-right: 15px solid #f0d6c5;
    }

    &-top {
        border-top: 15px solid #ba6b4a;
    }

    &-bottom {
        border-bottom: 15px solid #ba6b4a;
    }

    &__z {
        z-index: 998;
    }

    &-bg {
        background: url(../Images/team.jpeg) no-repeat center center fixed;
        background-size: contain;
    }
}

@media only screen and (max-width: 850px) {
    .row {
        display: flex;
        flex-direction: column-reverse !important;
    }

    .no-margin {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}