.navigation {
    height: 6rem;
    padding: .5rem 10rem;
    background-color: #353535;
    border-bottom: 5px solid #ba6b4a;
    box-shadow: 0px -5px 10px 5px #111;    
    &__link {
        color: #edd8c5;
        font-weight: 500;
        padding-bottom: .2rem;
        padding-top: .2rem;
        margin-right: 3.5rem;
        text-decoration: none;
        transition: .3s;
        border-bottom: 2px solid transparent;
        &--button {
            background: none !important;
            border-left: none !important;
            border-right: none !important;
            border-top: none !important;
            border-radius: 0 !important;
        }
        &:hover {
            color: #d4b8a1 !important;
            border-bottom: 2px solid #d4b8a1 !important;
        }
        &--active {
            color: #d4b8a1 !important;
            border-bottom: 2px solid #d4b8a1 !important;
        }
        &--no-margin {
            margin: 0;
        }
    }
    &__logo {
        height: 100%;
        width: auto;
        &:hover {
            border-color: transparent !important;
        }
    }
}

@media only screen and (max-width: 850px) {
    .mobile-nav {
        padding: .5rem 1rem !important;
        &-items {
            display: none !important;
        }
        &-actual {
            background-color: #ba6b4a;
        }
        &-fixed {
            z-index: 999;
            position: fixed;
            top: 0;
            width: 100%;
        }
    }
    }

@media only screen and (max-width: 1550px) {
.navigation {
    padding: .5rem 3rem;
}
}